@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'react-image-gallery/styles/scss/image-gallery';
@import 'c3/c3';
@import 'variables';
@import 'fonts';
@import 'typography';
@import 'base';
@import 'overrides/scrollbar';
@import 'overrides/pagination';
@import 'overrides/capacitor';
@import 'overrides/forms';
@import 'overrides/accordion';
@import 'overrides/chart';
