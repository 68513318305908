:root {
  --green-50: #f0fdf6;
  --green-100: #dbfdeb;
  --green-200: #baf8d8;
  --green-300: #84f1b9;
  --green-400: #47e192;
  --green-500: #26de81;
  --green-600: #13a65c;
  --green-700: #13824a;
  --green-800: #14673e;
  --green-900: #135435;
  --green-950: #042f1b;

  --zinc-50: #f5f5f6;
  --zinc-100: #e6e6e7;
  --zinc-200: #cfcfd2;
  --zinc-300: #adadb3;
  --zinc-400: #84848c;
  --zinc-500: #71717a;
  --zinc-600: #5a5a60;
  --zinc-700: #4d4c52;
  --zinc-800: #434347;
  --zinc-900: #3c3b3e;
  --zinc-950: #252527;
}
