.pagination {
  ul {
    & > li {
      &:first-child {
        @apply mr-auto;
      }
      &:last-child {
        @apply ml-auto;
      }
    }
  }
}
