html,
body,
#root {
  height: 100%;
  width: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  @apply styled-scrollbar;
}

html {
  &.menu-open,
  &.menu-open body{
    @apply overflow-hidden lg:overflow-auto;
  }
}

@media print {
  html,
  body,
  #root {
    overflow: visible;
  }
}
