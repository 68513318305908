body {
  font-family: Poppins, sans-serif;
  color: var(--text-color);
}

h1, h2, h3, h4, h5, h6 {
  color: var(--headline-color)
}

h1 {
}

h2 {
}

h3 {
}

h4 {
}

h5 {
}

h6 {
}

p {
}

.subtitle {
  font-size: 18px;
  letter-spacing: 0.15px;
}

button {
}
